.nav-tabs {
  @include media-breakpoint-only(xs) {
    flex-direction: column !important;
    &.nav {
      border-left: rem(1) solid $color-border;
    }
    .nav-item {
      line-height: 1 !important;
      &.active {
        border-bottom: none;
        border-left: rem(3) solid $color-primary !important;
      }
    }
  }

  @include media-breakpoint-up(sm) {
    .nav-item {
      line-height: 2.5;
      height: rem(52);

      &.active {
        border-bottom: rem(3) solid $color-primary !important;
      }
    }

    a {
      opacity: 1 !important;
    }
  }

  .nav-link {
    border: none;
    color: $color-text-dark;
    &.active {
      color: $color-primary !important;
      &:hover {
        background-color: $color-hover-nav-primary;
        border-radius: rem(10) !important;
      }
    }

    @include media-breakpoint-only(xs) {
      &.active {
        color: $color-primary !important;
        border-bottom: none !important;
        &:hover {
          border-radius: 0;
          border-bottom: none !important;
          opacity: 1;
        }
      }
      .tab-name {
        height: 100%;
        padding: rem(10) 0;
      }
    }
    &:hover {
      // border: rem(2) solid transparent;
      background-color: $color-hover-nav;
      border-radius: rem(10);
    }
  }

  .nav-item {
    margin-right: rem(5) !important;
    padding-left: rem(1) !important;
    padding-bottom: rem(2);
    .nav-link {
      border: rem(2) solid transparent;
    }
    a {
      padding: 0 rem(10);
      border-radius: 0;
    }
  }

  &.default-style {
    .nav-item {
      .nav-link {
        &.active {
          color: $color-text-dark !important;
          border: none !important;
        }
      }
    }
  }
  .disabled {
    pointer-events: none;
    opacity: 0.6;
  }
}

/*
// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) { ... }

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) { ... }
@include media-breakpoint-up(sm) { ... }

// Large devices (desktops, 99rem(2) and up)
@media (min-width: 99rem(2)) { ... }
@include media-breakpoint-up(md) { ... }

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) { ... }
@include media-breakpoint-up(lg) { ... }
*/

// @include media-breakpoint-down(sm) {
//   li {
//     flex: 100% !important;
//   }
// }

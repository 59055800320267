.label {
  @include font-size(11);
  border-radius: 1.5rem;
  padding: 4px 7px;
  text-transform: initial;
  font-weight: normal;
  display: inline-block;

  &.label-light {
    color: $color-title;
  }

  &.radius-sm {
    border-radius: 0.2rem;
  }

  &.label-status {
    background-color: $color-teal-light;
  }

  &.off {
    background-color: $color-gray-light;
  }
}

.label-invert {
  background: transparent;
  font-weight: bold;
  border-radius: 3px;

  &.label-primary {
    border-color: #005172;
    color: #005172;
  }

  &.label-success {
    border-color: #69c568;
    color: #69c568;
  }

  &.label-info {
    border-color: #3095b4;
    color: #3095b4;
  }

  .label-warning {
    border-color: #ffc341;
    color: #ffc341;
  }

  &.label-danger {
    border-color: #ec6660;
    color: #ec6660;
  }
}

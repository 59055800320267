.accordion-container {
  &.custom-accordion {
    .panel {
      .panel-heading {
        border-top: 1px solid $color-border;
      }
    }
  }

  .panel {
    border: 0;
    box-shadow: none;

    &.card {
      margin-bottom: 15px;
      border-bottom: 1px solid $color-border;
    }

    .panel-heading {
      border-top: 1px solid $color-border;
      padding: 10px 20px;

      .accordion-icon {
        min-width: 20px;
      }
    }

    .panel-title {
      padding: 0;
      font-weight: normal;

      i {
        vertical-align: baseline;
      }
    }

    .table {
      tr:nth-of-type(even) {
        background-color: transparent;
      }
    }
  }
}

.accordion-container-indicator.panel.card {
  margin-bottom: 0;
  border-bottom: 1px solid #d7e0e4;
}

.accordion-container-indicator .card-header {
  padding: 0 !important;
  margin-bottom: 0;
}

.accordion-container-indicator .panel {
  border-top: 0 solid #ccc !important;
}

.accordion-container-indicator .panel-title {
  margin-top: 0;
  padding: 11px 14px !important;
  font-size: 14px;
  color: #005172;
}

accordion {
  .fa {
    &.fa-angle-up {
      font-size: rem(20);
      font-weight: $font-weight-bold;
    }
    &.fa-angle-down {
      font-size: rem(20);
      font-weight: $font-weight-bold;
    }
  }
}

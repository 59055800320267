.form-control {
  color: $color-text;
}

.panel-title {
  text-transform: capitalize;
}

.required::after {
  content: " *";
}

.btn {
  &.btn-light {
    border: 1px solid $color-border;
  }

  &.btn-big {
    height: 45px;
  }
}

input[type="radio"]:focus {
  box-shadow: 0 0 5px transparent;
}

.label-text {
  font-weight: $font-weight-bold;
  color: $color-title;
}

a:hover {
  opacity: 0.75;
  text-decoration: none !important;
  cursor: pointer;
}

.hover-light:hover {
  opacity: 0.75;
  text-decoration: none !important;
}

.btn:focus,
.btn:active {
  outline: none !important;
  box-shadow: none;
}

/**
* Global app/site styles
* =================================
*/

body {
  background-color: $color-bg;
}

/**
* Default layout container
*/
.main-container {
  max-width: 100%;
  min-width: 90%;
  margin: 0 auto;
}

.middle-container {
  // min-height: 500px;
  min-height: 136.67px;
  max-width: 100%;
  min-width: 90%;
  margin: 0 auto;
  // content-visibility: auto;
}

.logo {
  line-height: 88px;
}

.textalign-center {
  text-align: center;
}

.text-black {
  color: $black;
}

.more-link-floating {
  @include font-size(14);
  position: absolute;
  bottom: 10px;
  right: 10px;
  font-weight: bold;
  color: $color-primary;
  cursor: pointer;

  i {
    position: relative;
    top: -1px;
  }

  &:hover {
    color: $color-teal-light;
    text-decoration: none;
  }
}

.title-text-dark {
  font-weight: 600;
  color: $color-title-dark;
}

.title-text {
  font-weight: 600;
  color: $color-title;
}

.title-text-primary {
  font-weight: 600;
  color: $color-primary;
}

.dark-text {
  color: $color-text-dark;
}

.normal-text {
  color: $color-text;
}

.light-text {
  color: $color-border;
}

.disabled-text {
  color: $color-gray-light;
}

.text-3xl {
  @include font-size(24);
}

.text-2xl {
  @include font-size(22);
}

.text-xl {
  @include font-size(20);
}

.text-lg {
  @include font-size(18);
}

.text-md {
  @include font-size(16);
}

.text-nm {
  @include font-size(14);
}

.text-sm-mid {
  @include font-size(13);
}

.text-sm {
  @include font-size(12);
}

.text-xs-mid {
  @include font-size(11);
}

.text-xs {
  @include font-size(10);
}

.semi-bold {
  font-weight: 600;
}

.pointer {
  cursor: pointer;
}

.ellipsis {
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box; /* stylelint-disable-line */
  outline: none;

  &.ellipsis-2 {
    -webkit-line-clamp: 2;
  }
}

.btn-primary,
.btn-primary:hover,
.btn-primary:active,
.btn-primary:visited {
  background-color: $color-primary !important;
  border-color: $color-primary !important;
}

/**
* Reset Default Styles
* =================================
*/

html {
  box-sizing: border-box;
}

body {
  position: relative;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

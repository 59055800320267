.name-dropdown {
  &.btn-group {
    &.open {
      border-color: red !important;
    }
    &.show {
      border-color: red !important;
    }
    border-color: red !important;
    .dropdown-toggle {
      text-decoration: none !important;
      color: $color-title;
      &::after {
        display: none;
      }
      .fa {
        &.fa-angle-up {
          font-size: rem(20);
          font-weight: $font-weight-bold;
        }
        &.fa-angle-down {
          font-size: rem(20);
          font-weight: $font-weight-bold;
        }
      }
    }
  }
}

/**
* Bootstrap - overridden styles
* =================================
*/

/**
* Grid breakpoints - overridden
*/

$grid-breakpoints: (
  xs: 0,
  sm: 600px,
  md: 896px,
  lg: 1000px,
  xl: 1280px
);

$container-max-widths: (
  sm: 600px,
  md: 896px,
  lg: 1000px,
  xl: 1220px
);

/**
* Responsive breakpoints - media query - overridden
*/
$screen-xs: 600px !default;
$screen-xs-min: $screen-xs !default;
$screen-phone: $screen-xs-min !default;

$screen-sm: 896px !default;
$screen-sm-min: $screen-sm !default;
$screen-tablet: $screen-sm-min !default;

$screen-md: 1000px !default;
$screen-md-min: $screen-md !default;
$screen-desktop: $screen-md-min !default;

$screen-lg: 1280px !default;
$screen-lg-min: $screen-lg !default;
$screen-lg-desktop: $screen-lg-min !default;

$screen-xs-max: ($screen-sm-min - 1) !default;
$screen-sm-max: ($screen-md-min - 1) !default;
$screen-md-max: ($screen-lg-min - 1) !default;
//

@import "../../../../node_modules/bootstrap/scss/bootstrap.scss";
// @import "../node_modules/bootstrap/scss/mixins/breakpoints";

/* You can add global styles to this file, and also import other style files */
@import "~@ng-select/ng-select/themes/default.theme.css";

/* Bootstrap theme */
// @import 'variables';
// @import '../node_modules/bootstrap/scss/bootstrap';
@import "../node_modules/bootstrap/scss/functions";
@import "../node_modules/bootstrap/scss/variables";
// @import "../node_modules/bootstrap/scss/mixins/breakpoints";

@import "./assets/sass/base/custom";
@import "./assets/sass/main";

@import '/src/assets/sass/abstracts/variables';
@import '/src/assets/sass/abstracts/functions';


.tooltip {
  border: 0;

  &.bottom {
    padding: 0.4rem 0;

    .arrow {
      &::before {
        border-bottom-color: $color-round-border;
        border-width: 0 0.5rem 0.5rem;
      }
    }

    .tooltip-inner {
      box-shadow: 3px 3px 7px -2px $color-round-border;
    }
  }

  &.top {
    padding: 0.4rem 0;

    .arrow {
      &::before {
        border-top-color: $color-round-border;
        border-width: 0.5rem;
      }
    }

    .tooltip-inner {
      box-shadow: 3px -1px 7px -2px $color-round-border;
    }
  }

  &.left {
    padding: 0 0.4rem;

    .arrow {
      &::before {
        border-left-color: $color-round-border;
        border-width: 0.5rem 0 0.5rem 0.5rem;
      }
    }

    .tooltip-inner {
      box-shadow: -1px 2px 7px -2px $color-round-border;
    }
  }

  &.right {
    padding: 0 0.4rem;

    .arrow {
      &::before {
        border-right-color: $color-round-border;
        border-width: 0.5rem 0.5rem 0.5rem 0;
      }
    }

    .tooltip-inner {
      box-shadow: 3px 3px 7px -2px $color-round-border;
    }
  }

  .tooltip-inner {
    @include font-size(11);
    background-color: $white !important;
    color: $color-title-dark !important;
    border: 1px solid $color-border !important;
    word-wrap: break-word;
  }

  .tooltip-arrow {
    border: 0;
  }
}

.ng-select {
  &.ng-select-multiple {
    .ng-select-container {
      height: rem(44);
      border-radius: rem(8);

      .ng-placeholder {
        color: #6b6d6f !important;
        font-weight: 500;
      }
    }
  }

  &.ng-select-single {
    .ng-select-container {
      height: rem(44);
      border-radius: rem(8);

      .ng-placeholder {
        color: #6b6d6f !important;
        font-weight: 500;
      }
    }

    &.ng-select-sm {
      .ng-select-container {
        height: rem(44);
        border-radius: rem(8);
      }
    }
  }

  .ng-select-container,
  .ng-dropdown-panel {
    border: 1px solid $color-border-dark;
  }

  .ng-value-label,
  .ng-option-label,
  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option,
  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-disabled {
    color: $color-title;
    font-weight: 500;
  }

  .ng-dropdown-panel.ng-select-bottom {
    border-top: 1px solid $color-border-dark;
  }

  .ng-option:hover {
    color: $color-hover;
  }

  .ng-arrow-wrapper {
    padding-right: rem(16) !important;
    .ng-arrow {
      border-color: $color-title transparent transparent;
    }
  }

  .ng-arrow-wrapper:hover .ng-arrow {
    border-top-color: $color-title;
  }

  .ng-clear-wrapper {
    padding-right: rem(20) !important;
  }

  .ng-select.ng-select-opened > .ng-select-container {
    border-color: $color-border-dark !important;
  }

  &.is-invalid {
    &.ng-invalid .ng-select-container {
      border-color: $dark-red;
    }

    &.ng-invalid .ng-arrow-wrapper .ng-arrow {
      border-color: $dark-red transparent transparent;
    }

    &.ng-invalid .ng-select-container .ng-placeholder {
      color: $dark-red !important;
    }
  }

  ::-webkit-scrollbar {
    width: 9px !important;
  }

  ::-webkit-scrollbar-track {
    background: #e8e8e8 !important;
  }

  ::-webkit-scrollbar-thumb {
    background: #c4c3c5 !important;
  }

  &.auto-grow {
    display: flex;

    // .ng-select-container {
    //   min-width: 100px;
    //   width: auto;
    // }

    .ng-dropdown-panel {
      width: auto;
    }
  }

  & .ng-select-container .ng-value-container {
    padding-right: rem(16);
    padding-left: rem(16);
  }
}

.ng-select.ng-select-opened > .ng-select-container .ng-arrow {
  border-color: $color-title transparent !important;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container {
  .ng-placeholder {
    top: 5px;
  }

  .ng-value {
    font-size: 14px;

    .ng-value-label {
      background-color: $white;
      padding-left: rem(16)
    }
  }
}

.ng-select .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  padding-left: rem(16);
}

.ng-select .ng-dropdown-panel.ng-select-bottom {
  border-color: $border-color;
}


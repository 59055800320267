.modal {
  &.fade {
    &.show {
      opacity: 1 !important;
    }

    .modal-dialog {
      transform: none;
      margin: 0 auto;
    }

    .modal-content {
      padding: 0;
    }

    .modal-header {
      margin: 0;
    }
  }

  .modal-xl {
    min-width: 80%;
    max-width: 80%;

    &.modal-dialog {
      overflow-y: initial !important;
    }

    .modal-body {
      height: 70vh;
      overflow-y: auto;
    }
  }
}

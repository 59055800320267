@import "../abstracts/variables";

.panel {
  .panel-body {
    overflow: initial;
  }
}

/** Badge */
.panel > .panel-heading .badge {
  color: initial;
  background-color: initial;
}

.badge {
  @include font-size(10);
  vertical-align: initial;
}

/** List Group */
.list-group {
  margin: 0;
}
/** card */
.card-body {
  padding: 1.25rem;
}

.card-header {
  background-color: $white;
}

/** buttons */
.btn {
  margin: 0;
  letter-spacing: 1px;
  border-width: 2px;
}

.btn-primary {
  &:hover,
  &:focus {
    background: #0180b6;
    border-color: #0180b6;
  }

  &[disabled] {
    background: #efefef;
    color: #c5c5c5;
    border-color: transparent;

    &:hover {
      background: #efefef;
    }
  }
}

.btn-default {
  &:hover,
  &:focus {
    background: #fff;
    border-color: #0180b6;
    color: #0180b6;
  }

  &[disabled] {
    border-color: #efefef;
    color: #c5c5c5;

    &:hover {
      color: #c5c5c5;
    }
  }
}

/** Hyperlinks */
body a {
  color: #0075A3;

  &:hover,
  &:focus {
    text-decoration: none;
    color: #0180b6;
  }
}

.checkbox-styled {
  margin: 5px 0;

  label {
    padding-left: 26px;
    font-size: 14px;
    cursor: pointer;
  }

  input[type="checkbox"] {
    &:checked {
      + span::before {
        top: 5px;
        left: 4px;
      }

      + span::after {
        background: $white;
        border-color: #d7e0e4;
      }
    }

    &:focus {
      box-shadow: none;

      + span::after {
        box-shadow: none;
      }
    }

    + span::before {
      color: #003a53;
      font-size: 14px;
    }

    + span::after {
      background: $white;
      border-radius: 0;
      border-width: 2px;
      width: 17px;
      height: 17px;
    }
  }
}

.input-group {
  flex-wrap: nowrap;
}
/** bread crumb */
.breadcrumb {
  margin: 0;

  > .active {
    font-weight: 600;
  }
}

/** Navigation Bar */
.dnb-global-header.navbar-default .navbar-brand {
  padding-right: 10px;
  padding-left: 10px;
}

.navbar {
  padding-top: 0;
  padding-bottom: 0;
  min-height: initial;
  margin-bottom: 0;
  border: 0;

  .navbar-nav {
    @include font-size(14);
  }
}

.input-group {
  display: inline-flex;

  &.input-group-sm {
    border: 1px solid #d9e2e6;
    border-radius: 2px;

    > .form-control {
      &:not(textarea) {
        height: 35px;
        border: 0;
        font-size: 14px !important;
      }
    }

    .input-group-append {
      height: 35px;

      i {
        font-size: 16px;
        border-left: 1px solid #d9e2e6;
        padding-left: 10px;
        color: #8f9399;
      }
    }
  }
}
/** Modal Dialog */
.modal-dialog {
  width: auto;

  .modal-header {
    background: transparent;
    margin: 0 -5px;

    .modal-title {
      color: $color-title;
      font-weight: 600;
    }

    .close {
      margin-top: -1rem;
      color: initial;
    }
  }
}

/** table */
.table.no-alternate > tbody > tr:nth-of-type(even) {
  background-color: transparent;
}

/** form controls */
.form-control {
  font-weight: 600;
}

input[type="range"] {
  display: initial;
}

select:not([multiple]),
select:not([size]) {
  background: transparent;
  background-image: linear-gradient(45deg, transparent 50%, $black 50%),
    linear-gradient(135deg, $black 50%, transparent 50%);
  background-position: calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px), 100% 0;
  background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
  background-repeat: no-repeat;
  appearance: none;
  padding-left: 10px;
  transition: none;

  &:focus {
    background-image: linear-gradient(45deg, $black 50%, transparent 50%),
      linear-gradient(135deg, transparent 50%, $black 50%);
    background-position: calc(100% - 15px) 1.25em, calc(100% - 20px) 1.25em,
      calc(100% - 1em) 1em;
    background-size: 5px 5px, 5px 5px, 1px 1.5em;
    background-repeat: no-repeat;
    border-color: $green;
    outline: 0;
  }

  /* stylelint-disable */
  &.dark {
    background-image: linear-gradient(45deg, transparent 50%, $white 50%),
      linear-gradient(135deg, $white 50%, transparent 50%);

    &:focus {
      background-image: linear-gradient(45deg, $white 50%, transparent 50%),
        linear-gradient(135deg, transparent 50%, $white 50%);
    }
  }
}

.form-group {
  .radio-options {
    padding-top: 6px;
  }
}

.form-control {
  // height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  height: 35px;
}

/* Popover */
.popover-arrow {
  &::before {
    display: none !important;
  }
}

body genesis-container-root .ui-table .ui-table-tbody > tr > td {
  padding: 0.857em;
}

.breadcrumb > li a {
  color: #009ece;
  font-weight: 600;
}

.breadcrumb > li {
  font-size: 16px;
}

.breadcrumb-item + .breadcrumb-item::before {
  font-family: "Font Awesome 5 Pro";
  font-display: swap;
  font-weight: 900;
  content: "\f105"; // .fa-angle-right
  color: #009ece;
}

.form-check-label {
  padding-left: 10px;
  font-weight: 600;
}

.help-info-modal {
  td {
    padding: 0.857em;
  }
}
.link-style{
  text-decoration: underline !important;
}
.link-style:hover{
  text-decoration: underline !important;
}
/**
 * Utility Mixins
 * =================================
 * - clearfix
 * - font-face
 * - sizing shortcuts
 *   - size
 *   - square
 * - resizable
 */

// Clearfix
@mixin clearfix {
  &::after {
    content: "";
    display: table;
    clear: both;
  }
}

// @font-face
// @include font-face(proxima-nova, /css/fonts/proxima-nova, normal, italic, false);
// @mixin font-face(
//   $family-name,
//   $font-path,
//   $font-weight: normal,
//   $font-style: normal
// ) {
//   @font-face {
//     font-family: $family-name;
//     src: url("#{$font-path}.eot");
//     src: url("#{$font-path}.eot?#iefix") format("embedded-opentype"),
//       url("#{$font-path}.woff") format("woff"),
//       url("#{$font-path}.ttf") format("truetype"),
//       url("#{$font-path}.svg##{$family-name}") format("svg");
//     font-weight: $font-weight;
//     font-style: $font-style;
//   }
// }

// Centre-align a block level element
// @include center-block
@mixin center-block() {
  display: block;
  margin: 0 auto;
}

// Input placeholder text
@mixin placeholder($color: lighten(#000, 70%)) {
  :-ms-input-placeholder {
    color: $color;
  }

  :-moz-placeholder {
    color: $color;
  }

  ::-webkit-input-placeholder {
    color: $color;
  }
}

// Text selection
@mixin text-selection($color-selection, $color-selection-bg) {
  ::-moz-selection {
    color: $color-selection;
    background: $color-selection-bg;
    text-shadow: none;
  }

  ::selection {
    color: $color-selection;
    background: $color-selection-bg;
    text-shadow: none;
  }
}

// Vertical align
// @include vertical-align(50px);
@mixin vertical-align($argument) {
  line-height: $argument;
  height: $argument;
}

// Text truncation
// @include truncate(300px);
// @include truncate(100%);
@mixin truncate($truncation-boundary) {
  @include text-overflow();
  max-width: $truncation-boundary;
}

/**
 * Sizing shortcuts
 */

// @include size(10px, 30px);
@mixin size($width: 5px, $height: 5px) {
  height: $height;
  width: $width;
}

// @include square(50px);
@mixin square($size: 5px) {
  @include size($size, $size);
}

// Make any element resizable for prototyping
// @include resizable();
@mixin resizable($direction: both) {
  resize: $direction; // Options are horizontal, vertical, both
  overflow: auto; // Safari fix
}

/**
 * position
 * =================================
 * Usage:
 * @include position(absolute, 10px 20px 30px 10px);
 */
@mixin position($position: relative, $coordinates: 0 0 0 0) {
  @if type-of($position) == list {
    $coordinates: $position;
    $position: relative;
  }

  $top: nth($coordinates, 1);
  $right: nth($coordinates, 2);
  $bottom: nth($coordinates, 3);
  $left: nth($coordinates, 4);

  position: $position;

  @if $top == auto {
    top: $top;
  } @else if not(unitless($top)) {
    top: $top;
  }

  @if $right == auto {
    right: $right;
  } @else if not(unitless($right)) {
    right: $right;
  }

  @if $bottom == auto {
    bottom: $bottom;
  } @else if not(unitless($bottom)) {
    bottom: $bottom;
  }

  @if $left == auto {
    left: $left;
  } @else if not(unitless($left)) {
    left: $left;
  }
}
/**
 * vertical-center
 * =================================
 * Vertically center any element. Needs support for CSS tranforms.
 * Usage:
 * @include vertical-center;
 */
@mixin vertical-center {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

/**
 * font-size
 * =================================
 * Usage:
 * @include font-size(13);
 */
@mixin font-size($sizeValue: 12) {
  font-size: ($sizeValue) + px;
  font-size: rem($sizeValue);
}

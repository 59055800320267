/**
 * Typography
 * =================================
 * - Base
 * - Paragraphs
 * - Headings
 * - Type sizes
 * - Misc
 * - Utilities
 *
 * Body font size, leadings etc have been set in _variables.scss
 */
@import "../abstracts/variables";

html {
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  // @include font-size(rem($font-size-base));
  font-size: rem($font-size-base);
  font-family: $font-family-sans;
  font-display: swap;
  line-height: $leading-base;
  color: $color-text;
  opacity: 0.99;
  line-height: 1.7145;
}

/**
 * Paragraphs
 */
p {
  font-family: $font-family-sans;
  margin-top: 0;
  font-display: swap;
  margin-bottom: $baseline;

  // Measure - ideally about 65 chars per line
  // max-width: #{type(base) * 30}px;
}

/**
 * Headings
 */
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-family: $font-family-headings;
  font-display: swap;
  font-weight: $font-weight-headings;
  line-height: $line-height-headings;
  text-rendering: optimizelegibility; // Fix the character spacing for headings
  margin-top: 0;

  small {
    font-weight: normal;
  }
}

h5 {
  color: $gray;
}

// For example: h3.h1 or h3.alpha
h1,
.h1,
.alpha {
  margin-bottom: $baseline * 2;
}

h5,
.h5,
.epsilon,
h6,
.h6,
.zeta {
  margin-bottom: 0;
  font-weight: 600;
}

// Only give these headings a margin-top if they are after other elements
* + h1,
* + .h1,
* + .alpha,
* + h2,
* + .h2,
* + .beta,
* + h3,
* + .h3,
* + .gamma,
* + h4,
* + .h4,
* + .delta {
  margin-top: $baseline;
}

small {
  font-size: 80%;
}

/**
 * Miscellaneous
 */

// Emphasis
strong,
b {
  font-weight: $font-weight-bold;
}

em,
i {
  font-style: italic;
}

/**
 * Blockquotes
 */
blockquote {
  padding-left: 10px;
  margin: $baseline;
  border-left: 4px solid lighten(#000, 80%);

  p {
    margin-bottom: 0;
    font-weight: 300;
  }

  small {
    display: block;
    color: lighten(#000, 70%);

    &::before {
      content: "\2014 \00A0";
    }
  }
}

// Quotes
q,
blockquote {
  &::before,
  &::after {
    content: "";
  }
}

cite {
  font-style: normal;
}

// Addresses styling not present in S5, Chrome
dfn {
  font-style: italic;
}

// Addresses styling not present in IE6/7/8/9
mark {
  background: $yellow;
  padding: 2px 4px;
  border-radius: 3px;
}

// Prevents sub and sup affecting line-height in all browsers
// gist.github.com/413930
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

/**
 * Utilities
 */
.text-centre, // British or US English spellings..
.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

@include text-selection($color-selection, $color-selection-bg);

// Horizontal rules
hr {
  margin: ($baseline) 0;
  border: 0;
  height: 1px;
  background-color: $hr-color;
}

.hyphenate {
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
}

/* stylelint-disable */
h5.bold {
  color: $color-title;
  font-weight: bold;
}


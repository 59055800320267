.alert {
  padding: 10px;
  padding: 0.75rem 1.25rem;
  border: 1px solid;
  border-radius: 0;
  border-left-width: 5px;
  color: $color-title-dark;
  background-color: $white;

  &.alert-info {
    border-color: $color-info !important;
  }

  &.alert-danger {
    border-color: $color-danger !important;
  }

  &.alert-success {
    border-color: $color-success !important;
  }

  &.alert-warning {
    border-color: $color-warning !important;
  }

  &.alert-dismissible {
    padding-right: 4rem;

    .close {
      padding: 0.75rem 1.25rem;
      top: 0;
      right: 0;
      position: absolute;
    }
  }
}

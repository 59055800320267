@import "variables";

/**
* Helper classes
* =================================
* Non-semantic helper classes
*/

/**
* Clearfix
*/
.clearfix::after {
  content: "";
  clear: both;
  display: table;
}

// Hide from both screenreaders and browsers: h5bp.com/u
.is-hidden {
  display: none !important;
  visibility: hidden !important;
}

.is-shown {
  display: block;
}

// Hide only visually, but have it available for screenreaders: h5bp.com/v
.is-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;

  // Extends the .visuallyhidden class to allow the element to be focusable when navigated to via the keyboard: h5bp.com/p
  &.focusable:active,
  &.focusable:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
  }
}

// Hide visually and from screenreaders, but maintain layout
.is-invisible {
  visibility: hidden;
}

// flip text horizontally
.flip-h {
  transform: scale(-1, 1);
  -moz-transform: scale(-1, 1);
  -webkit-transform: scale(-1, 1);
  -o-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  transform: scale(-1, 1);
}

// circle based on content
.circle {
  border-radius: 50%;
  padding: 0.25em;

  /* Center the text contents */
  display: inline-flex;
  align-items: center;
  justify-content: center;

  span,
  div {
    overflow: visible;
    height: 0;
    margin-top: -1em;
  }

  &::after {
    content: "";
    display: block;

    /* Ensure the element is a square */
    height: 0;
    width: 100%;
    padding-bottom: 100%;
  }
}

.is-invalid {
  &::-webkit-input-placeholder {
    color: $dark-red;
  }

  &::-ms-input-placeholder {
    color: $dark-red;
  }

  &::placeholder {
    color: $dark-red;
  }
}

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: $white;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: #c4c3c5;
  border-radius: 2px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #a4a3a5;
}

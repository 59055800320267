@import "./functions";
/**
* Variables
* =================================
* - Colors
* - Typography
* - Layout
* - Paths
*/

/* stylelint-disable indentation */

/**
 * Colors
 * =================================
 * - Generic colors
 * - Color scheme
 * - Common colors
 * - Links
 * - Text selection
 * - <hr>
 * - Risk color mapping
 * - Any others..
 */

// Generic colors
$blue: #288ad6;
$lightblue: #c6e9f4;
$malibu: #48caff;
$green: #80ac60;
$red: #fc6d67;
$yellow: #feef6d;
$orange: #fe7c08;
$pink: #ff708e;
$purple: #8e44ad;
$gray: #95a5a6;
$dark-pink: #cf3154;
$dark-red: #dc3545;

$black: #000;
$white: #fff;

$color-kickoff-blue: #181830;
$color-kickoff-orange: #fe7c08;
$color-kickoff-moderate: #ffba00;

// Color Scheme
$color-primary: #0075a3;
$color-secondary: $orange;
$color-highlight: $lightblue;
$color-teal-light: #01b2aa;
$color-gray-light: #b3c0c6;
$color-light-panel: #f9f9f9;
$color-backgound-gray: #f3f4f5;
$color-hover-nav: #cbcdce;
$color-hover-nav-primary: #dbeef5;



// Common colors
$color-title: #161919;
$color-title-dark: #000000;
$color-title-blue: #005172;
$color-text: #656769;
$color-text-dark: #161919;
$color-text-header: #4d6d7a;
$color-text-header: #0d7594;

$color-bg: #f1f6f8;
$color-border: #d7e0e4;
$color-border-selected: #00b2a9;
$color-blue-border: #009ece;
$color-border-dark: #656769;
$color-border-light: #CBCDCE;

$color-success: #80c272;
$color-info: #00b2a9;
$color-danger: #ef5d5d;
$color-warning: #ffbc07;
$color-hover: #effbff;
$error: #BE0B0E;
$warning: #BE4B00;
// Links
$color-link: #009ece;
$color-link-default: $color-highlight;
$color-link-visited: $color-link-default;
$color-link-hover: darken($color-link-default, 20%);
$color-link-active: darken($color-link-default, 20%);

// Text Selection
$color-selection: #fff;
$color-selection-bg: $color-primary;
$color-selection-border: #ccc;
$color-round-border: #4d6d7a;

// <hr> border color
$hr-color: lighten(#000, 70%);

// Risk color mapping
$high-risk: #ff5c56;
$medium-risk: #ffc03b;
$low-risk: #34c76f;
$empty-risk: #b8c9db;

/**
* Typography variables
* =================================
*/

//Font-weight
// Useful to set here when bold webfonts come as 400 font-weight.

$font-weight: normal;
$font-style: normal;
$font-weight-bold: bold;
$font-weight-semibold: 600;
$font-weight-narrow: 100;
$font-weight-headings: bold; // instead of browser default, bold
$line-height-headings: 1.1;

// Font stacks
// $font-family-sans: "Open Sans", Helvetica, Arial, sans-serif; // genesis

@font-face {
  
  font-family: "Avenir LT Std";
  src: url("../../fonts/otf/AvenirLTStd-Medium.otf") format("opentype"); /* IE9 Compat Modes */
  // src: url('webfont.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  //      url('webfont.woff2') format('woff2'), /* Super Modern Browsers */
  //      url('webfont.woff') format('woff'), /* Pretty Modern Browsers */
  //      url('webfont.ttf')  format('truetype'), /* Safari, Android, iOS */
  //      url('webfont.svg#svgFontName') format('svg'); /* Legacy iOS */
  font-weight: $font-weight;
  font-display: swap !important;
  font-style: $font-style;
}
$font-family-sans: "Avenir LT Std", Fallback, sans-serif; // ivr
$font-family-headings: $font-family-sans; // using inherit will use $font-family-sans for all headings

/* Base sizes:
* Set this in pixels (but do not add px),
* the font-size mixin will convert to REMS
*/
$font-size-base: 16;
$font-size-xs: rem($font-size-base - 4);
$font-size-s: rem($font-size-base - 2);
$font-size-m: rem($font-size-base + 2);
$font-size-l: rem($font-size-base + 4);
$font-size-xl: rem($font-size-base + 6);
$font-size-xxl: rem($font-size-base + 8);
$font-size-xxxl: rem($font-size-base + 10);

$leading-base: 1.4;
$line-height-base: round($font-size-base * $leading-base); // 20 * 1.4
$baseline: $line-height-base * 1px;

/**
* Breakpoints variables
* =================================
*/
$layout-margin: 0; // [in pixels] Space outside the grid
$layout-min-width: 960; // [in pixels] Minimum width for layout
$layout-max-width: 1367; // [in pixels] Maximum width for layout

/**
* Path variables
* =================================
*/
$img-path: "/assets/images"; // Usage: background: url('#{$img-path}/img.jpg') 0 0 no-repeat;

/**
* Z-index variables
* =================================
*/
$zi-low: 10;
$zi-mid: 100;
$zi-high: 1000;

/**
 * Get Value
 * =================================
 * Usage:
 *
 * getValue(mid, $map) - specify a key from a Sass map
 */
@use "sass:math";

@function get-value($val, $map: "") {
  @if not map-has-key($map, $val) {
    @warn "No value found in `$type` map for `#{$val}`. Property omitted.";
  }

  @return map-get($map, $val);
}


/**
 * Get font-family
 * =================================
 * Get font-family from $font-family map
 *
 * font()
 *
 * Usage:
 * font(system) - uses 'system' font stack
 * font(sans) - uses the 'sans' font stack
 */
@function font($val, $map: $font-family) {
  @return getValue($val, $map);
}

/**
 * px to em converter
 * =================================
 * Convert pixels to ems
 *
 * Usage:
 * font-size : em(12);
 * font-size : em(12, 24); // If the parent is another value say 24px
 */

@function em($pxval, $base: $font-size-base) {
  @return math.div($pxval, $base) * 1em;
}

/**
 * Convert a map of breakpoints to ems
 * =================================
 * Takes a map of breakpoints (in px) and a base-font-size
 *
 * Usage:
 $breakpoints: map-to-em((
 	narrow : 400px,
 	mid    : 750px,
 	wide   : 1000px,
 	huge   : 1250px
 ), 16);
 */

@function map-to-em($breakpoints, $base: $font-size-base) {
  $new-breakpoints: ();

  @each $name, $pxValue in $breakpoints {
    $em-value: em(strip-units($pxValue), $base);
    $new-breakpoints: map-merge
      (
        $new-breakpoints,
        (
          $name: $em-value
        )
      );
  }

  @return $newBreakpoints;
}

/**
 * px to rem conversion
 * =================================
 * Usage:
 * rem(14px)
 */

 @function stripUnit($value) {
     @return math.div($value, $value * 0 + 1);
 }
 @function rem($pxValue) {
     @return #{math.div(stripUnit($pxValue), stripUnit($font-size-base))}rem;
 }

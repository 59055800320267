.gen-icon {
  width: 17px;
  height: 16px;
  background-size: 100% 100%;

  &.icon-lg {
    width: 48px;
    height: 48px;
  }

  &.delete-icon {
    background-image: url("../../images/feature/delete_icon.svg");
  }
}

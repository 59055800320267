.footer {
  color: #A2AAAD;
  background-color: #232323;
  padding-top: 16px;
  padding-bottom: 16px;
  margin-bottom: 0px;
}


.navbar {
  position: relative;
  min-height: 50px;
  margin-bottom: 22px;
  border: 1px solid transparent;
}

.container {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.footer-link {
  a {
    color: #A2AAAD;
    font-weight: normal;
  }
}

.p-b-15 {
  padding-bottom: 15px;
}

.m-b-10 {
  margin-bottom: 10px;
}

.m-r-10 {
  margin-right: 10px;
}

.b-r-1 {
  border-right: 1px solid $color-border;
}

.b-b-1 {
  border-bottom: 1px solid $color-border;
}

.ml--8 {
  margin-left: -8px;
}

.m-t-10 {
  margin-top: 10px;
}

.m-t-5 {
  margin-top: 5px;
}

.border-1 {
  border: 1px solid $color-border;
  border-radius: 5px;
}

.btn.border-1 {
  border-radius: 2px;
}

.move-right {
  float: right;
}

.cursor-pointer {
  cursor: pointer;
}

.primary-color {
  color: $color-primary;
}
